import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBIcon,
  MDBModalHeader,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBSwitch,
} from "mdbreact";
import { SAVE, UPDATE } from "../../../../services/redux/slices/appointments";
import { fullName } from "../../../../services/utilities";
import { useToasts } from "react-toast-notifications";

import {
  FIND as SCHEDULES,
  RESET as SCHEDRESET,
} from "../../../../services/redux/slices/schedules";

export default function Modal({ show, toggle, selected, willCreate }) {
  const { isLoading } = useSelector(({ appointments }) => appointments),
    APPOINTMENTS = useSelector(({ appointments }) => appointments.collections),
    { collections } = useSelector(({ schedules }) => schedules),
    CLIENTS = useSelector(({ users }) => users.collections),
    BUSINESSES = useSelector(({ businesses }) => businesses.collections),
    SERVICES = useSelector(({ services }) => services.collections),
    { token } = useSelector(({ auth }) => auth),
    [form, setForm] = useState({
      schedule: "",
      service: "",
      client: "",
      date: "",
      status: "Pending",
      notes: "",
      isPriority: true,
    }),
    [schedules, setSchedules] = useState([]),
    [clients, setClients] = useState([]),
    [services, setServices] = useState([]),
    [appointments, setAppointments] = useState([]),
    status = [
      "Pending",
      "Rejected",
      "Confirmed",
      "Cancelled",
      "Rescheduled",
      "Completed",
    ],
    days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    dispatch = useDispatch();
  const { addToast } = useToasts();

  const handleUpdate = () => {
    dispatch(
      UPDATE({
        data: { ...form, _id: selected._id },
        token,
      })
    );

    setForm({
      schedule: "",
      service: "",
      client: "",
      date: "",
      status: "Pending",
      notes: "",
      isPriority: true,
    });
    toggle();
  };

  const handleCreate = () => {
    dispatch(
      SAVE({
        data: { ...form, _id: selected._id },
        token,
      })
    );

    setForm({
      schedule: "",
      service: "",
      client: "",
      date: "",
      status: "Pending",
      notes: "",
      isPriority: true,
    });
    toggle();
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (willCreate) {
      handleCreate();
    } else {
      handleUpdate();
    }
  };

  useEffect(() => {
    setSchedules(collections.filter(e => e.deleted === false));
    setClients(CLIENTS.filter(e => e.wasBanned === false));
    setAppointments(APPOINTMENTS.filter(e => e.deleted === false));
    setServices(SERVICES.filter(e => e.deleted === false));
  }, [collections, CLIENTS, APPOINTMENTS, SERVICES]);

  useEffect(() => {
    const handleInject = async () => {
      if (selected._id) {
        let updates = {
          _id: selected._id,
          schedule: selected?.schedule?._id,
          client: selected?.client?._id,
          service: selected?.service?._id,
          date: selected?.date,
          status: selected?.status,
          notes: selected?.notes,
          isPriority: selected?.isPriority,
        };

        if (selected.schedule && selected.schedule._id) {
          updates.schedule = selected.schedule._id;
        }
        if (selected.client && selected.client._id) {
          updates.client = selected.client._id;
        }
        if (selected.service && selected.service._id) {
          updates.service = selected.service._id;
        }

        setForm(updates);
      }
    };
    handleInject();
  }, [selected]);

  const handleChange = (key, value) => setForm({ ...form, [key]: value });

  const handlleChangeService = (key, value) => {
    setForm({ ...form, [key]: value });
    const i = services.findIndex(service => service._id === value);
    let employees = [];
    if (i >= 0) {
      services[i].employees.forEach(b => {
        employees.push(`"${b._id}"`);
      });

      if (employees.length > 0) {
        dispatch(
          SCHEDULES({
            token,
            key: {
              employee: `{"$in": [${employees}]}`,
            },
          })
        );
      }
    }
  };

  const handleChangeDate = (key, value) => {
    var date = new Date(value);
    const sched = schedules.findIndex(d => d._id === form?.schedule);
    const i = days.findIndex(d => d === schedules[sched].day);
    if (date.getDay() !== i) {
      addToast(`Please select date day is ${days[i]}`, {
        placement: "bottom",
        appearance: "warning",
      });
    } else {
      const booked = appointments.findIndex(app => {
        return (
          app?.schedule?._id === form?.schedule &&
          new Date(app?.date).toISOString().split("T")[0] ===
            date.toISOString().split("T")[0] &&
          (app.status !== "Pending" ||
            app.status !== "Cancelled" ||
            app.status !== "Rejected")
        );
      });
      if (booked > -1) {
        addToast(
          `The date and schedule is taken please select other date or change the schedule`,
          {
            appearance: "warning",
          }
        );
      } else {
        setForm({ ...form, [key]: value });
      }
    }
  };

  return (
    <MDBModal
      modalClassName="z-index-1"
      className="zindex-dropdown"
      style={{ "z-index": 999 }}
      isOpen={show}
      toggle={toggle}
      backdrop={false}
      tabIndex={"-1"}
      disableFocusTrap={false}
    >
      <MDBModalHeader
        toggle={toggle}
        className="light-blue darken-3 white-text"
      >
        <MDBIcon icon="user" className="mr-2" />
        {willCreate ? "Create" : "Update"} {selected.name || "a Service"}
      </MDBModalHeader>
      <MDBModalBody className="mb-0">
        <form onSubmit={handleSubmit}>
          <MDBRow>
            <MDBCol>
              <MDBSelect
                getValue={e => handlleChangeService("service", e[0])}
                className="colorful-select dropdown-primary hidden-md-down"
                label="Services"
              >
                <MDBSelectInput />
                <MDBSelectOptions>
                  {services.map((service, index) => {
                    let isSelected = false;

                    if (selected._id) {
                      const _service = willCreate
                        ? form.serviceule
                        : form.serviceule || selected?.serviceule?._id;
                      if (_service === service._id) {
                        isSelected = true;
                      }
                    }

                    return (
                      <MDBSelectOption
                        key={`service-list-${index}`}
                        value={service._id}
                        selected={isSelected}
                      >
                        {`${service.name}`}
                      </MDBSelectOption>
                    );
                  })}
                </MDBSelectOptions>
              </MDBSelect>
              <MDBSelect
                getValue={e => handleChange("schedule", e[0])}
                className="colorful-select dropdown-primary hidden-md-down"
                label="Schedule"
              >
                <MDBSelectInput />
                <MDBSelectOptions>
                  {schedules.map((sched, index) => {
                    let isSelected = false;

                    if (selected._id) {
                      const _sched = willCreate
                        ? form.schedule
                        : form.schedule || selected?.schedule?._id;
                      if (_sched === sched._id) {
                        isSelected = true;
                      }
                    }

                    return (
                      <MDBSelectOption
                        key={`sched-list-${index}`}
                        value={sched._id}
                        selected={isSelected}
                      >
                        {`${fullName(sched?.employee?.fullName)}- ${
                          sched.day
                        }: ${sched.time}`}
                      </MDBSelectOption>
                    );
                  })}
                </MDBSelectOptions>
              </MDBSelect>
              <MDBSelect
                getValue={e => handleChange("client", e[0])}
                className="colorful-select dropdown-primary hidden-md-down"
                label="Client"
              >
                <MDBSelectInput />
                <MDBSelectOptions>
                  {clients.map((client, index) => {
                    let isSelected = false;

                    if (selected._id) {
                      const _client = willCreate
                        ? form.client
                        : form.client || selected?.client?._id;
                      if (_client === client._id) {
                        isSelected = true;
                      }
                    }

                    return (
                      <MDBSelectOption
                        key={`client-list-${index}`}
                        value={client._id}
                        selected={isSelected}
                      >
                        {fullName(client?.fullName)}
                      </MDBSelectOption>
                    );
                  })}
                </MDBSelectOptions>
              </MDBSelect>
              <MDBInput
                disabled={form.schedule ? false : true}
                // min={new Date()}
                type="date"
                label="Date"
                value={willCreate ? form.date : form.date || selected.date}
                onChange={e => handleChangeDate("date", e.target.value)}
                required
                icon="clock"
              />
              <MDBRow>
                <MDBCol md="12">
                  <MDBSelect
                    getValue={e => handleChange("status", e[0])}
                    className="colorful-select dropdown-primary hidden-md-down"
                    label="Status"
                  >
                    <MDBSelectInput />
                    <MDBSelectOptions>
                      {status.map((stat, index) => {
                        let isSelected = false;

                        if (selected._id) {
                          const _stat = willCreate
                            ? form.status
                            : form.status || selected.status;
                          if (_stat === stat) {
                            isSelected = true;
                          }
                        }

                        return (
                          <MDBSelectOption
                            key={`stat-list-${index}`}
                            value={stat}
                            selected={isSelected}
                          >
                            {stat}
                          </MDBSelectOption>
                        );
                      })}
                    </MDBSelectOptions>
                  </MDBSelect>
                </MDBCol>
              </MDBRow>

              <MDBInput
                type="textarea"
                label="Notes"
                value={willCreate ? form.notes : form.notes || selected.notes}
                onChange={e => setForm({ ...form, notes: e.target.value })}
                required
                icon="heading"
              />
            </MDBCol>
          </MDBRow>
          <div className="text-center mb-1-half">
            <MDBBtn
              type="submit"
              disabled={isLoading}
              color="info"
              className="mb-2"
              rounded
            >
              {willCreate ? "submit" : "update"}
            </MDBBtn>
          </div>
        </form>
      </MDBModalBody>
    </MDBModal>
  );
}
