import React from "react";
import "./index.css";
import { MDBIcon, MDBSideNavLink } from "mdbreact";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { KEY } from "../../services/redux/slices/businesses";

export default function BottomNav() {
  const dispatch = useDispatch();
  const handleSearch = async (search, key) => {
    dispatch(KEY(key));
  };

  return (
    <div class="wrap">
      <div class="menu">
        <div class="list">
          <div class="link-wrap d-flex justify-content-around align-items-center">
            <MDBSideNavLink
              title="Home"
              topLevel
              to={"/dashboard"}
              className="text-light "
            >
              <MDBIcon icon="home" size="lg" />
            </MDBSideNavLink>
            <MDBSideNavLink
              topLevel
              to={"/bookings"}
              title="Appointment"
              className="text-light d-flex flex-col"
            >
              <MDBIcon icon="clock" size="lg" />
            </MDBSideNavLink>
            <MDBSideNavLink
              onClick={async () => {
                const { value: _search } = await Swal.fire({
                  title: "What are you looking for?",
                  text: "Provide a keyword and we will find it for you.",
                  icon: "question",
                  input: "text",
                  confirmButtonText: "Search",
                  inputValidator: value => {
                    if (!value) {
                      return "You need to write something!";
                    }
                  },
                });

                if (_search) {
                  const _key = _search.toUpperCase();

                  // setSearch(_key);
                  // setSelected([]);

                  handleSearch(true, _key);
                }
              }}
              title="Search"
              topLevel
              className="text-light "
              to="/dashboard"
            >
              <MDBIcon icon="search" size="lg" />
            </MDBSideNavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
