import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBIcon,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact";
import { connect } from "react-redux";
import Notification from "./Notification";

class TopNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
    this.onClick = this.onClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleToggleClickA = this.handleToggleClickA.bind(this);
    this.windowWidth = this.props.windowWidth;
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  handleToggleClickA() {
    this.props.onSideNavToggleClick();
  }

  render() {
    const navStyle = {
      paddingLeft: this.props.toggle ? "16px" : "240px",
      transition: "padding-left .3s",
    };

    const { auth } = this.props;
    return (
      <MDBNavbar
        className="flexible-MDBNavbar"
        light
        expand="md"
        scrolling
        fixed="top"
        style={{ zIndex: 3 }}
      >
        {" "}
        {(this.windowWidth > 540 || auth?.role?.name !== "CLIENT" || !auth) && (
          <div
            onClick={this.handleToggleClickA}
            key="sideNavToggleA"
            style={{
              lineHeight: "32px",
              marginleft: "1em",
              verticalAlign: "middle",
              cursor: "pointer",
            }}
          >
            <MDBIcon icon="bars" color="white" size="lg" />
          </div>
        )}
        <MDBNavbarBrand href="#" style={navStyle}>
          <strong>{this.props.routeName}</strong>
        </MDBNavbarBrand>
        <MDBNavbarNav expand="sm" right style={{ flexDirection: "row" }}>
          <Notification />
          <MDBDropdown>
            <MDBDropdownToggle nav>
              <MDBIcon icon="user" />
              &nbsp;
              <span className="d-none d-md-inline">Profile</span>
            </MDBDropdownToggle>
            <MDBDropdownMenu right style={{ minWidth: "200px" }}>
              <MDBDropdownItem disabled={!auth._id} href="/profile">
                My Account
              </MDBDropdownItem>
              <MDBDropdownItem
                onClick={() => {
                  if (auth._id) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("email");
                  }
                  window.location.href = "/";
                }}
              >
                Log Out
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbarNav>
      </MDBNavbar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth.auth, // Replace with your actual state property
  };
};

export default connect(mapStateToProps)(TopNavigation);
