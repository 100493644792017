import React from "react";

export default function Agenda({ event }) {
  return (
    <div
      style={{
        display: "flex",
        padding: "10px",
        borderBottom: "1px solid #ddd",
      }}
    >
      <div style={{ flex: 1 }}>{event.title}</div>
      <div style={{ flex: 1 }}>{event.service}</div> {/* Custom column */}
      <div style={{ flex: 1 }}>{event.notes}</div> {/* Custom column */}
      <div style={{ flex: 1 }}>{event.status}</div> {/* Custom column */}
    </div>
  );
}
