import React, { useEffect, useState } from "react";
import Calendar from "react-big-calendar";
import moment from "moment";
import { useSelector } from "react-redux";
import { fullName } from "../../../../services/utilities";
import Agenda from "./agenda";
import CustomAgendaHeader from "./header";

export default function ReactCalendar() {
  const { collections } = useSelector(({ appointments }) => appointments),
    [schedules, setSchedules] = useState([]);

  const handleStatus = status => {
    switch (status) {
      case "Pending":
        return "#ffb703";
      case "Rejected":
        return "#e63946";
      case "Cancelled":
        return "#ccd5ae";
      case "Rescheduled":
        return "#a8dadc";
      case "Confirmed":
        return "#06d6a0";
      case "Completed":
        return "#80ed99";

      default:
        return "#G001524";
    }
  };

  useEffect(() => {
    let appointments = [];
    collections
      .filter(e => e.deleted === false)
      .forEach(item => {
        console.log(item?.schedule?.time);
        console.log(moment(item?.schedule?.time, "HH:mm").format("HH:mm"));
        console.log(
          new Date(
            item.date +
              "T" +
              moment(item?.schedule?.time, "HH:mm").format("HH:mm")
          )
        );
        appointments.push({
          title: `${item?.service?.business?.name} - ${fullName(
            item?.schedule?.employee?.fullName
          )} `,
          status: `Status: ${item.status}`,
          service: `Service: ${item?.service?.name}`,
          notes: `notes: ${item.notes}`,
          start: new Date(
            item.date +
              "T" +
              moment(item?.schedule?.time, "HH:mm").format("HH:mm")
          ),
          end: new Date(
            item.date +
              "T" +
              moment(item?.schedule?.time, "HH:mm").format("HH:mm")
          ),
          bgColor: handleStatus(item.status),
        });
      });
    setSchedules(appointments);
  }, [collections]);
  return (
    <Calendar
      eventPropGetter={(event, start, end, isSelected) => {
        return {
          style: { backgroundColor: event.bgColor },
        };
      }}
      localizer={Calendar.momentLocalizer(moment)}
      defaultDate={new Date()}
      defaultView="agenda"
      events={schedules}
      step={60}
      popup={true}
      components={{
        agenda: {
          event: Agenda,
          status: CustomAgendaHeader,
        },
      }}
      style={{ height: "70vh", marginBottom: "1.5rem" }}
    />
  );
}
