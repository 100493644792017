import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "./notFound";

import SUPERADMIN from "./platforms/superadmin/routes";
import ADMINISTRATOR from "./platforms/administrator/routes";
import EMPLOYEE from "./platforms/employee/routes";
import CLIENT from "./platforms/client/routes";

//global
import Profile from "../components/profile";
import ViewProfile from "../components/profile/view.jsx";
import { useSelector } from "react-redux";

export default function Routes() {
  const { role } = useSelector(({ auth }) => auth);

  const handleRoutes = () => {
    const collection = {
      SUPERADMIN,
      ADMINISTRATOR,
      EMPLOYEE,
      CLIENT,
    };

    const routes = collection[role.name] || [];

    return routes.map(({ path, component }, index) => (
      <Route key={`route-${index}`} exact path={path} component={component} />
    ));
  };

  return (
    <Switch>
      {handleRoutes()}
      <Route path="/profile" exact component={ViewProfile} />
      <Route path="/profile/update" exact component={Profile} />

      <Route component={NotFound} />
    </Switch>
  );
}
