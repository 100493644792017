import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBIcon,
  MDBModalHeader,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
} from "mdbreact";
import { SAVE, UPDATE } from "../../../../services/redux/slices/feedbacks";
import { fullName } from "../../../../services/utilities";
import { useParams } from "react-router";
import businesses from "../../../../services/redux/slices/businesses";

export default function Modal({ show, toggle, selected, willCreate }) {
  const { isLoading } = useSelector(({ feedbacks }) => feedbacks),
    CLIENTS = useSelector(({ users }) => users.collections),
    { token, auth } = useSelector(({ auth }) => auth),
    { id } = useParams(),
    [form, setForm] = useState({
      message: "",
      rate: 0,
      client: auth._id,
      business: id,
    }),
    [clients, setClients] = useState([]),
    dispatch = useDispatch();

  const handleUpdate = () => {
    dispatch(
      UPDATE({
        data: { ...form, _id: selected._id },
        token,
      })
    );

    setForm({
      message: "",
      rate: 0,
      client: auth._id,
      business: id,
    });
    toggle();
  };

  const handleCreate = () => {
    dispatch(
      SAVE({
        data: { ...form, _id: selected._id },
        token,
      })
    );

    setForm({
      message: "",
      rate: 0,
      client: auth._id,
      business: id,
    });
    toggle();
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (willCreate) {
      handleCreate();
    } else {
      handleUpdate();
    }
  };

  useEffect(() => {
    setClients(CLIENTS.filter(client => client?.role?.name === "CLIENT"));
  }, [CLIENTS]);

  useEffect(() => {
    const handleInject = async () => {
      if (selected._id) {
        let updates = {
          _id: selected._id,
          business: selected?.business?._id,
          message: selected.message,
          rate: selected.rate,
          clients: "",
        };

        if (selected.client && selected.client._id) {
          updates.client = selected.client._id;
        }

        setForm(updates);
      }
    };
    handleInject();
  }, [selected]);

  const handleChange = (key, value) => setForm({ ...form, [key]: value });

  return (
    <MDBModal
      isOpen={show}
      toggle={toggle}
      backdrop={true}
      disableFocusTrap={false}
    >
      <MDBModalHeader
        toggle={toggle}
        className="light-blue darken-3 white-text"
      >
        <MDBIcon icon="user" className="mr-2" />
        {willCreate ? "Create" : "Update"} {selected.name || "a Feedback"}
      </MDBModalHeader>
      <MDBModalBody className="mb-0">
        <form onSubmit={handleSubmit}>
          <MDBRow>
            <MDBCol>
              <MDBSelect
                getValue={e => handleChange("client", e[0])}
                className="colorful-select dropdown-primary hidden-md-down"
                label="Clients"
              >
                <MDBSelectInput />
                <MDBSelectOptions>
                  {clients.map((emps, index) => {
                    let isSelected = false;

                    if (selected._id) {
                      const _emps = selected.client;
                      if (_emps === emps._id) {
                        isSelected = true;
                      }
                    }

                    return (
                      <MDBSelectOption
                        key={`emps-list-${index}`}
                        value={emps._id}
                        selected={isSelected}
                      >
                        {fullName(emps.fullName)}
                      </MDBSelectOption>
                    );
                  })}
                </MDBSelectOptions>
              </MDBSelect>
              <MDBInput
                type="number"
                label="Rate"
                step={"0.1"}
                value={willCreate ? form.rate : form.rate || selected.rate}
                onChange={e => setForm({ ...form, rate: e.target.value })}
                required
                icon="star"
              />
              <MDBInput
                type="text"
                label="message"
                value={
                  willCreate ? form.message : form.message || selected.message
                }
                onChange={e => setForm({ ...form, message: e.target.value })}
                required
                icon="comment-alt"
              />
            </MDBCol>
          </MDBRow>
          <div className="text-center mb-1-half">
            <MDBBtn
              type="submit"
              disabled={isLoading}
              color="info"
              className="mb-2"
              rounded
            >
              {willCreate ? "submit" : "update"}
            </MDBBtn>
          </div>
        </form>
      </MDBModalBody>
    </MDBModal>
  );
}
