const sidebar = [
  {
    name: "Dashboard",
    icon: "tachometer-alt",
    path: "/dashboard",
  },
  {
    name: "Employees",
    icon: "user-alt",
    path: "/employees",
  },

  {
    name: "My Businesses",
    icon: "building",
    path: "/businesses",
  },
  {
    name: "Schedules",
    icon: "calendar-alt",
    path: "/schedules",
  },
  {
    name: "Appointments",
    icon: "clock",
    path: "/bookings",
  },
];

export default sidebar;
