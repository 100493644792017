import Appointments from "./appointments";
import Dashboard from "./dashboard";

const routes = [
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/bookings",
    component: Appointments,
  },
];

export default routes;
