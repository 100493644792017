import Dashboard from "./dashboard";
import Schedules from "./schedules";
import Services from "./services";
import Businesses from "./businesses";
import Appointments from "./appointments";
import Employees from "./users";
import Feedbacks from "./feedbacks";

const routes = [
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/businesses",
    component: Businesses,
  },
  {
    path: "/services/:business",
    component: Services,
  },
  {
    path: "/feedbacks/:id",
    component: Feedbacks,
  },
  {
    path: "/schedules",
    component: Schedules,
  },
  {
    path: "/bookings",
    component: Appointments,
  },
  {
    path: "/employees",
    component: Employees,
  },
];

export default routes;
