import React, { useEffect, useState } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBSideNavLink,
} from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import { BROWSE } from "../../../../services/redux/slices/businesses";
import {
  ENDPOINT,
  PresetImage,
  fullName,
  globalSearch,
} from "../../../../services/utilities";
import "./index.css";
import moment from "moment";

export default function Dashboard() {
  const { token } = useSelector(({ auth }) => auth),
    { collections, key } = useSelector(({ businesses }) => businesses),
    [businesses, setBusinesses] = useState([]),
    dispatch = useDispatch();

  useEffect(() => {
    dispatch(BROWSE(token));
  }, [token, dispatch]);

  useEffect(() => {
    setBusinesses(collections.filter(e => e.deleted === false));
  }, [collections]);

  useEffect(() => {
    setBusinesses(globalSearch(collections, key));
  }, [key, collections]);

  return (
    <MDBContainer fluid className="mb-5 ">
      <MDBRow>
        {businesses.map((item, index) => (
          <MDBCol md={4} size="6" className="my-2">
            <MDBCard
              title={item.details}
              collection={true}
              className="rounded"
              ecommerce={true}
              pricing={true}
            >
              <MDBCardImage
                className="card-img"
                src={`${ENDPOINT}/assets/businesses/${item._id}.png`}
                onError={e =>
                  (e.target.src =
                    "https://bootstrapmade.com/demo/templates/QuickStart/assets/img/features-3.jpg")
                }
                fluid
                alt="business profile"
              />
              <div
                className="mask"
                style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
              ></div>
              <MDBCardBody>
                <MDBCardTitle className="fs-md-3 fs-6">
                  {item.name}
                </MDBCardTitle>
                <MDBCardText>{item.details}</MDBCardText>
                <div
                  className="d-none d-md-flex p-2 rounded"
                  style={{ backgroundColor: "#ADE8f4" }}
                >
                  <div className="d-flex text-center ">
                    <img
                      height={"50px"}
                      className="rounded-circle float-start profile-img"
                      src={`${ENDPOINT}/assets/profile/${
                        item?.owner?.email || ""
                      }.jpg`}
                      onError={e =>
                        (e.target.src = PresetImage(item?.owner?.isMale))
                      }
                      alt="owner profile"
                    />
                    <div className="d-flex flex-col align-items-center justify-content-center ml-3 ">
                      <p className="fs-6">{fullName(item?.owner?.fullName)}</p>
                    </div>
                  </div>
                </div>
                <MDBSideNavLink
                  className="btn btn-sm btn-md-lg bg-success"
                  key={`sidebar-${index}`}
                  to={`/chat/${item._id}`}
                >
                  Chat
                </MDBSideNavLink>
              </MDBCardBody>
              <MDBCardText>
                <hr />
                <small className="text-muted">
                  {`${moment(item?.operatingHours?.start, "HH:mm").format(
                    "h:mm A"
                  )}-${moment(item?.operatingHours?.end, "HH:mm").format(
                    "h:mm A"
                  )}`}
                </small>
              </MDBCardText>
            </MDBCard>
          </MDBCol>
        ))}
      </MDBRow>
    </MDBContainer>
  );
}
