const locations = location => {
  if (location.split("/")[1] === "services") {
    return "Services of business";
  }
  if (location.split("/")[1] === "chat") {
    return "Bot AI";
  }
  if (location.split("/")[1] === "feedbacks") {
    return "Feedbacks";
  }
  switch (location) {
    case "/dashboard":
      return "Dashboard";

    case "/roles":
      return "Listed Roles";

    case "/users":
      return "Listed Users";

    case "/profile":
      return "Your Profile";

    case "/profile/update":
      return "Update Your Profile";

    case "/bookings":
      return "Appointments";

    case "/employees":
      return "Your Employees";

    case "/businesses":
      return "Businesses";

    case "/schedules":
      return "Schedules";

    case "/services":
      return "Services";
    case "/bots":
      return "Appointment AI";

    default:
      return "Looks like you're lost.";
  }
};

export default locations;
