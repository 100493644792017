import React, { useEffect, useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBBadge,
  MDBBtn,
  MDBView,
  MDBIcon,
} from "mdbreact";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN, MAILER } from "../../../../services/redux/slices/statistics";
import { useToasts } from "react-toast-notifications";

const barChartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    xAxes: [
      {
        barPercentage: 1,
        gridLines: {
          display: true,
          color: "rgba(0, 0, 0, 0.1)",
        },
        ticks: {
          fontColor: "#7e8591",
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
          color: "rgba(0, 0, 0, 0.1)",
        },
        ticks: {
          beginAtZero: true,
          min: 0,
          fontColor: "#7e8591",
        },
      },
    ],
  },
  legend: {
    labels: {
      fontColor: "#7e8591",
      fontSize: 16,
    },
  },
};

export default function Dashboard() {
  const { collections } = useSelector(({ statistics }) => statistics),
    { token } = useSelector(({ auth }) => auth),
    [models, setModels] = useState([]),
    [chart, setChart] = useState([]),
    [card, setCard] = useState({
      pending: 0,
      rejected: 0,
      confirmed: 0,
      cancelled: 0,
      rescheduled: 0,
      completed: 0,
    }),
    [yr, setYr] = useState(new Date().getFullYear()),
    [months] = useState([
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]),
    [statuses] = useState([
      "Pending",
      "Rejected",
      "Confirmed",
      "Cancelled",
      "Rescheduled",
      "Completed",
    ]),
    dispatch = useDispatch();
  const { addToast } = useToasts();

  useEffect(() => {
    if (token) {
      dispatch(ADMIN({ token }));
    }
  }, [token, dispatch]);

  useEffect(() => {
    setModels(
      collections.filter(
        e => e.deleted === false && new Date(e.createdAt).getFullYear() === yr
      )
    );
  }, [collections, yr]);

  const handleStatus = status => {
    switch (status) {
      case "Pending":
        return "#ffb703";
      case "Rejected":
        return "#e63946";
      case "Cancelled":
        return "#ccd5ae";
      case "Rescheduled":
        return "#a8dadc";
      case "Confirmed":
        return "#06d6a0";
      case "Completed":
        return "#80ed99";

      default:
        return "#G001524";
    }
  };
  useEffect(() => {
    let _card = { ...card };
    let _chart = [];
    statuses.forEach(status => {
      let _months = [...months];
      let _data = [];
      _months.forEach((item, i) => {
        _data.push(
          models.filter(
            e =>
              new Date(e.date).getMonth() === i &&
              status.toLowerCase() === e.status.toLowerCase()
          ).length
        );
      });
      var o = Math.round,
        r = Math.random,
        s = 255;
      var data = {
        label: status,
        data: _data,
        backgroundColor: [
          handleStatus(status),
          handleStatus(status),
          handleStatus(status),
          handleStatus(status),
          handleStatus(status),
          handleStatus(status),
          handleStatus(status),
          handleStatus(status),
          handleStatus(status),
          handleStatus(status),
          handleStatus(status),
          handleStatus(status),
        ],
        borderWidth: 1,
      };
      _chart.push(data);
      _card[status.toLowerCase()] = models.filter(
        e => status.toLowerCase() === e.status.toLowerCase()
      ).length;
    });
    setChart(_chart);
    setCard(_card);
  }, [models]);

  const handleYear = () => {
    const yrNow = new Date().getFullYear();
    let dyr = 2024;
    for (dyr; dyr <= yrNow; dyr++) {
      return (
        <MDBSelectOption value={dyr} selected={dyr === yr}>
          {dyr}
        </MDBSelectOption>
      );
    }
  };
  const handleNotification = () => {
    dispatch(MAILER(token));

    addToast(`The email notifications send`, {
      appearance: "success",
    });
  };

  return (
    <MDBContainer fluid id="v6" className="mb-5">
      <MDBBtn onClick={handleNotification} color="info">
        Send notification
      </MDBBtn>
      <section className="mb-5">
        <MDBRow>
          <MDBCol xl="4" md="6" className="mb-4 mb-r">
            <MDBCard>
              <MDBRow className="mt-3">
                <MDBCol md="5" size="5" className="text-left pl-4">
                  <button
                    tag="a"
                    floating
                    size="lg"
                    color="primary"
                    style={{ backgroundColor: "#ffb703", padding: 0 }}
                    className="ml-4 btn Ripple-parent btn-lg  btn-floating ml-4"
                  >
                    <MDBIcon icon="times" size="2x" />
                  </button>
                </MDBCol>
                <MDBCol md="7" col="7" className="text-right pr-5">
                  <h1 className="ml-4 mt-4 mb-2 font-weight-bold">
                    {card.pending}
                  </h1>
                </MDBCol>
              </MDBRow>
              <MDBRow className="my-3">
                <MDBCol md="7" col="7" className="text-left pl-4">
                  <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">
                    Pending
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBCol>
          <MDBCol xl="4" md="6" className="mb-4 mb-r">
            <MDBCard>
              <MDBRow className="mt-3">
                <MDBCol md="5" size="5" className="text-left pl-4">
                  <button
                    tag="a"
                    floating
                    size="lg"
                    color="primary"
                    style={{ backgroundColor: "#e63946", padding: 0 }}
                    className="ml-4 btn Ripple-parent btn-lg  btn-floating ml-4"
                  >
                    <MDBIcon icon="user-times" size="2x" />
                  </button>
                </MDBCol>
                <MDBCol md="7" col="7" className="text-right pr-5">
                  <h1 className="ml-4 mt-4 mb-2 font-weight-bold">
                    {card.rejected}
                  </h1>
                </MDBCol>
              </MDBRow>
              <MDBRow className="my-3">
                <MDBCol md="7" col="7" className="text-left pl-4">
                  <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">
                    Rejected
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBCol>
          <MDBCol xl="4" md="6" className="mb-4 mb-r">
            <MDBCard>
              <MDBRow className="mt-3">
                <MDBCol md="5" size="5" className="text-left pl-4">
                  <button
                    tag="a"
                    floating
                    size="lg"
                    color="primary"
                    style={{ backgroundColor: "#ccd5ae", padding: 0 }}
                    className="ml-4 btn Ripple-parent btn-lg  btn-floating ml-4"
                  >
                    <MDBIcon icon="times" size="2x" />
                  </button>
                </MDBCol>
                <MDBCol md="7" col="7" className="text-right pr-5">
                  <h1 className="ml-4 mt-4 mb-2 font-weight-bold">
                    {card.cancelled}
                  </h1>
                </MDBCol>
              </MDBRow>
              <MDBRow className="my-3">
                <MDBCol md="7" col="7" className="text-left pl-4">
                  <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">
                    Cancelled
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBCol>
          <MDBCol xl="4" md="6" className="mb-4 mb-r">
            <MDBCard>
              <MDBRow className="mt-3">
                <MDBCol md="5" size="5" className="text-left pl-4">
                  <button
                    tag="a"
                    floating
                    size="lg"
                    color="primary"
                    style={{ backgroundColor: "#a8dadc", padding: 0 }}
                    className="ml-4 btn Ripple-parent btn-lg  btn-floating ml-4"
                  >
                    <MDBIcon icon="calendar-day" size="2x" />
                  </button>
                </MDBCol>
                <MDBCol md="7" col="7" className="text-right pr-5">
                  <h1 className="ml-4 mt-4 mb-2 font-weight-bold">
                    {card.rescheduled}
                  </h1>
                </MDBCol>
              </MDBRow>
              <MDBRow className="my-3">
                <MDBCol md="7" col="7" className="text-left pl-4">
                  <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">
                    Rescheduled
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBCol>
          <MDBCol xl="4" md="6" className="mb-4 mb-r">
            <MDBCard>
              <MDBRow className="mt-3">
                <MDBCol md="5" size="5" className="text-left pl-4">
                  <button
                    tag="a"
                    floating
                    size="lg"
                    color="primary"
                    style={{ backgroundColor: "#06d6a0", padding: 0 }}
                    className="ml-4 btn Ripple-parent btn-lg  btn-floating ml-4"
                  >
                    <MDBIcon icon="calendar-check" size="2x" />
                  </button>
                </MDBCol>
                <MDBCol md="7" col="7" className="text-right pr-5">
                  <h1 className="ml-4 mt-4 mb-2 font-weight-bold">
                    {card.confirmed}
                  </h1>
                </MDBCol>
              </MDBRow>
              <MDBRow className="my-3">
                <MDBCol md="7" col="7" className="text-left pl-4">
                  <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">
                    Confirmed
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBCol>
          <MDBCol xl="4" md="6" className="mb-4 mb-r">
            <MDBCard>
              <MDBRow className="mt-3">
                <MDBCol md="5" size="5" className="text-left pl-4">
                  <button
                    tag="a"
                    floating
                    size="lg"
                    color="primary"
                    style={{ backgroundColor: "#80ed99", padding: 0 }}
                    className="ml-4 btn Ripple-parent btn-lg  btn-floating ml-4"
                  >
                    <MDBIcon icon="check-double" size="2x" />
                  </button>
                </MDBCol>
                <MDBCol md="7" col="7" className="text-right pr-5">
                  <h1 className="ml-4 mt-4 mb-2 font-weight-bold">
                    {card.completed}
                  </h1>
                </MDBCol>
              </MDBRow>
              <MDBRow className="my-3">
                <MDBCol md="7" col="7" className="text-left pl-4">
                  <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">
                    Completed
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBCard cascade narrow>
          <MDBRow>
            <MDBCol xl="5" md="12" className="mr-0">
              <MDBView
                cascade
                className="gradient-card-header light-blue lighten-1"
              >
                <h4 className="h4-responsive mb-0 font-weight-bold">
                  Bookings
                </h4>
              </MDBView>
              <MDBCardBody cascade className="pb-3">
                <MDBRow className="pt-3 card-body">
                  <MDBCol md="12">
                    <h4>
                      <MDBBadge className="big-badge light-blue lighten-1">
                        Year
                      </MDBBadge>
                    </h4>
                    <MDBSelect getValue={e => setYr(e[0])} value={yr}>
                      <MDBSelectInput selected={yr} />
                      <MDBSelectOptions>{handleYear()}</MDBSelectOptions>
                    </MDBSelect>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCol>
            <MDBCol md="12" xl="7">
              <MDBView cascade className="gradient-card-header white">
                <Bar
                  data={{
                    labels: months,
                    datasets: chart,
                  }}
                  options={barChartOptions}
                  height={150}
                />
              </MDBView>
            </MDBCol>
          </MDBRow>
        </MDBCard>
      </section>
    </MDBContainer>
  );
}
