import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import { FIND } from "../../../../services/redux/slices/services";
import { ENDPOINT } from "../../../../services/utilities";
import "./index.css";
import { useParams } from "react-router";
import { RESET } from "../../../../services/redux/slices/appointments";
import Modal from "./modal";

import {
  BROWSE as SCHEDULES,
  RESET as SCHEDRESET,
} from "../../../../services/redux/slices/schedules";
import { useToasts } from "react-toast-notifications";
export default function Services() {
  const { token } = useSelector(({ auth }) => auth),
    { collections } = useSelector(({ services }) => services),
    { message, isSuccess } = useSelector(({ appointments }) => appointments),
    [services, setServices] = useState([]),
    [selected, setSelected] = useState({}),
    [showModal, setShowModal] = useState(false),
    { id } = useParams(),
    { addToast } = useToasts(),
    dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(SCHEDULES(token));
      dispatch(
        FIND({
          token,
          key: {
            business: `{"$eq": "${id}"}`,
          },
        })
      );
    }
    return () => {
      dispatch(RESET());
      dispatch(SCHEDRESET());
    };
  }, [token, dispatch]);

  useEffect(() => {
    setServices(collections.filter(e => e.deleted === false));
  }, [collections]);

  const toggleModal = () => {
    if (selected._id) {
      setSelected({});
    }
    if (message) {
      dispatch(RESET());
    }
    setShowModal(!showModal);
  };
  const handleBook = selected => {
    setSelected(selected);
    toggleModal();
  };

  useEffect(() => {
    if (message) {
      addToast(message, {
        appearance: isSuccess ? "success" : "error",
      });
    }
  }, [isSuccess, message, addToast]);
  return (
    <MDBContainer fluid className="mb-5 ">
      <MDBRow>
        {services.map((item, index) => (
          <MDBCol md={4} size="6" className="my-2">
            <MDBCard
              collection={true}
              className="rounded"
              ecommerce={true}
              pricing={true}
            >
              <MDBCardImage
                className="card-img"
                src={`${ENDPOINT}/assets/services/${item._id}.jpg`}
                style={{ height: "300px" }}
                onError={e =>
                  (e.target.src =
                    "https://bootstrapmade.com/demo/templates/QuickStart/assets/img/features-3.jpg")
                }
                fluid
                alt="business profile"
              />
              <a>
                <div
                  className="mask"
                  style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                ></div>
              </a>
              <MDBCardBody>
                <MDBCardTitle className="fs-md-3 fs-6">
                  {item.name}
                </MDBCardTitle>
                <MDBCardText>{item.details}</MDBCardText>
                <MDBBtn
                  onClick={() => handleBook(item)}
                  className="btn btn-sm btn-md-lg "
                  color="success"
                >
                  Book
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        ))}
      </MDBRow>
      <Modal
        key={"service-initial-create"}
        selected={{}}
        willCreate={true}
        show={showModal}
        toggle={toggleModal}
      />
    </MDBContainer>
  );
}
