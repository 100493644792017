// CustomAgendaHeader.js
import React from "react";

const CustomAgendaHeader = () => {
  return (
    <div
      style={{
        display: "flex",
        padding: "10px",
        borderBottom: "2px solid #ddd",
        fontWeight: "bold",
      }}
    >
      <div style={{ flex: 1 }}>Title</div>
      <div style={{ flex: 1 }}>Start</div>
      <div style={{ flex: 1 }}>End</div>
      <div style={{ flex: 1 }}>Location</div> {/* Custom column */}
      <div style={{ flex: 1 }}>Location</div> {/* Custom column */}
    </div>
  );
};

export default CustomAgendaHeader;
