import { configureStore } from "@reduxjs/toolkit";
import auth from "./slices/auth";
import users from "./slices/users";
import roles from "./slices/roles";
import projects from "./slices/projects";
import tasks from "./slices/tasks";
import events from "./slices/events";
import businesses from "./slices/businesses";
import services from "./slices/services";
import schedules from "./slices/schedules";
import appointments from "./slices/appointments";
import notifications from "./slices/notifications";
import statistics from "./slices/statistics";
import chats from "./slices/chats";
import feedbacks from "./slices/feedbacks";

const store = configureStore({
  reducer: {
    auth,
    users,
    roles,
    projects,
    tasks,
    events,
    businesses,
    services,
    schedules,
    appointments,
    notifications,
    statistics,
    chats,
    feedbacks,
  },
});

export default store;
