import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BROWSE,
  DESTROY,
  RESET,
  UPDATE,
} from "../../../../services/redux/slices/businesses";
import {
  FIND as ADMIN,
  RESET as USERRESET,
} from "../../../../services/redux/slices/users";
import {
  fullAddress,
  fullName,
  globalSearch,
} from "../../../../services/utilities";
import { useToasts } from "react-toast-notifications";
import Swal from "sweetalert2";
import Modal from "./modal";
import DataTable from "../../../../components/dataTable";
import moment from "moment";

export default function Businesses() {
  const [isActive, setIsActive] = useState(true),
    [businesses, setBusinesses] = useState([]),
    [selected, setSelected] = useState({}),
    [willCreate, setWillCreate] = useState(true),
    [showModal, setShowModal] = useState(false),
    { token } = useSelector(({ auth }) => auth),
    { collections, message, isSuccess, isLoading } = useSelector(
      ({ businesses }) => businesses
    ),
    { addToast } = useToasts(),
    dispatch = useDispatch();

  const handleSwitch = () => setIsActive(!isActive);

  useEffect(() => {
    if (token) {
      dispatch(BROWSE(token));
      dispatch(
        ADMIN({
          token,
          key: {
            role: `{"$in": ["647dd9120874515a8fc47afc", "647dd1e9dced91b0b39444ad"]}`,
          },
        })
      );
    }

    return () => {
      dispatch(RESET());
      dispatch(USERRESET());
    };
  }, [token, dispatch]);

  useEffect(() => {
    setBusinesses(collections.filter(item => item.deleted !== isActive));
  }, [isActive, collections]);

  const toggleModal = () => {
    if (selected._id) {
      setSelected({});
    }
    if (message) {
      dispatch(RESET());
    }
    setShowModal(!showModal);
  };

  const handleUpdate = selected => {
    setSelected(selected);
    if (willCreate) {
      setWillCreate(false);
    }
    toggleModal();
  };

  const handleCreate = () => {
    if (!willCreate) {
      setWillCreate(true);
    }
    toggleModal();
  };

  const handleDestroy = selected =>
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to delete ${selected.length} Business(es).`,
      icon: "question",
      confirmButtonText: "Proceed",
    }).then(res => {
      if (res.isConfirmed) {
        const toDestroy = selected.map(item => ({
          _id: item._id,
          deleted: true,
        }));

        dispatch(
          DESTROY({
            data: toDestroy.length > 1 ? toDestroy : toDestroy[0],
            token,
          })
        );
      }
    });

  const handleRestore = selected =>
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to restore ${selected.length} Business(es).`,
      icon: "question",
      confirmButtonText: "Proceed",
    }).then(res => {
      if (res.isConfirmed) {
        const toRestore = selected.map(item => ({
          _id: item._id,
          deleted: false,
        }));

        dispatch(
          UPDATE({
            data: toRestore.length > 1 ? toRestore : toRestore[0],
            token,
          })
        );
      }
    });

  useEffect(() => {
    if (message) {
      addToast(message, {
        appearance: isSuccess ? "success" : "error",
      });
    }
  }, [isSuccess, message, addToast]);

  const handleSearch = async (willSearch, key) => {
    if (willSearch) {
      setBusinesses(globalSearch(businesses, key));
    } else {
      setBusinesses(collections);
    }
  };

  return (
    <>
      <DataTable
        isLoading={isLoading}
        title={isActive ? "Active Businesses" : "Archive Businesses"}
        array={businesses}
        actions={[
          {
            _icon: "plus",
            _function: handleCreate,
            _condition: () => isActive,
            _shouldReset: true,
            _disabledOnSearch: true,
          },

          {
            _icon: isActive ? "pen-alt" : "undo-alt",
            _function: isActive ? handleUpdate : handleRestore,
            _haveSelect: true,
            _allowMultiple: !isActive,
            _shouldReset: true,
          },
          {
            _icon: "trash",
            _function: handleDestroy,
            _condition: () => isActive,
            _haveSelect: true,
            _shouldReset: true,
          },
          {
            _icon: "sync",
            _function: handleSwitch,
            _shouldReset: true,
            _disabledOnSearch: true,
          },
        ]}
        tableHeads={[
          {
            _text: "Owner",
          },
          {
            _text: "Name",
          },
          {
            _text: "Details",
          },
          {
            _text: "Address",
          },
          {
            _text: "Operating Hours",
          },
          {
            _text: "Created At",
          },
        ]}
        tableBodies={[
          {
            _key: "owner",
            _format: data => fullName(data?.fullName),
          },
          {
            _key: "name",
          },
          {
            _key: "details",
          },
          {
            _key: "address",
            _format: data => fullAddress(data),
          },
          {
            _key: "operatingHours",
            _format: data =>
              `${moment(data.start, "HH:mm").format("h:mm A")}-${moment(
                data.end,
                "HH:mm"
              ).format("h:mm A")}`,
          },
          {
            _key: "createdAt",
            _format: data => new Date(data).toLocaleString(),
          },
        ]}
        disableSearch={!isActive}
        handleSearch={handleSearch}
        toggleComponent={isActive}
      />
      <Modal
        key={selected._id || "business-initial-create"}
        selected={selected}
        willCreate={willCreate}
        show={showModal}
        toggle={toggleModal}
      />
    </>
  );
}
