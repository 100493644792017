import {
  MDBBadge,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBNavLink,
} from "mdbreact";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FIND, RESET, UPDATE } from "../../services/redux/slices/notifications";

export default function Notification() {
  const [notifications, setNotifications] = useState([]),
    { auth, token } = useSelector(({ auth }) => auth),
    { collections } = useSelector(({ notifications }) => notifications),
    dispatch = useDispatch();

  useEffect(() => {
    if (token && auth && auth._id) {
      dispatch(
        FIND({
          token,
          key: {
            user: `{"$eq": "${auth._id}"}`,
          },
        })
      );
    }

    return () => {
      dispatch(RESET());
    };
  }, [auth, token, dispatch]);

  useEffect(() => {
    setNotifications(collections);
  }, [collections]);

  const handleRead = id => {
    dispatch(UPDATE({ data: { _id: id, isRead: true }, token }));
  };

  return (
    <MDBDropdown>
      <MDBDropdownToggle nav caret>
        {notifications.length > 0 && (
          <MDBBadge color="red" className="mr-2">
            {notifications.filter(e => e.isRead === false).length}
          </MDBBadge>
        )}
        <MDBIcon icon="bell" />
        <span className="d-none d-md-inline">Notifications</span>
      </MDBDropdownToggle>
      <MDBDropdownMenu right style={{ minWidth: "400px" }}>
        {notifications.map(item => {
          var d1 = new Date(item.createdAt);
          var d2 = new Date();

          var diff = d2.getTime() - d1.getTime();

          var daydiff = diff / (1000 * 60 * 60 * 24);
          return (
            <MDBNavLink
              onClick={() => handleRead(item._id)}
              to={item.link ? item.link : "#!"}
            >
              <MDBIcon icon={item.icon} className="mr-2" />
              {item.title}
              <span className="float-right">
                <MDBIcon icon="clock" /> {Math.round(daydiff)}
              </span>
            </MDBNavLink>
          );
        })}
      </MDBDropdownMenu>
    </MDBDropdown>
  );
}
