import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBView,
  MDBCardTitle,
  MDBCardBody,
  MDBCardGroup,
  MDBCardFooter,
  MDBIcon,
  MDBMask,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBAvatar,
  MDBBtn,
} from "mdbreact";
import { useSelector } from "react-redux";
import {
  PresetImage,
  fullAddress,
  fullName,
} from "../../../services/utilities";
import { useHistory } from "react-router";

export default function ViewProfile({ user }) {
  const { image, auth } = useSelector(({ auth }) => auth),
    [account, setAccount] = useState({}),
    history = useHistory();

  useEffect(() => {
    setAccount(user && user._id ? user : auth);
  }, [auth, user]);

  return (
    <MDBContainer fluid>
      <MDBRow>
        <MDBCol md="12">
          <div className="d-flex justify-content-between align-items-center mt-3 mb-4">
            <h4>
              <strong>{auth.fullName?.fname}'s profile</strong>
            </h4>
            <MDBBtn
              color="info"
              rounded
              size="sm"
              onClick={() => history.push("/profile/update")}
            >
              Update profile
            </MDBBtn>
          </div>
          <MDBCard testimonial className="profile-card text-center mb-4 mt-5">
            <MDBAvatar
              tag="img"
              alt={`view-profile-${account._id}`}
              src={image}
              onError={e => (e.target.src = PresetImage(account.isMale))}
              className="z-depth-1-half mx-auto"
            />
            <MDBCardBody>
              <MDBCardTitle>
                <strong>{fullName(account.fullName)}</strong>
              </MDBCardTitle>
              <h5>{account.role?.name}</h5>
              {auth.address && (
                <p className="dark-grey-text">
                  {fullAddress(auth.address, false)}
                </p>
              )}

              <MDBBtn floating tag="a" color="">
                <MDBIcon fab icon="facebook" className="dark-grey-text" />
              </MDBBtn>
              <MDBBtn floating tag="a" color="">
                <MDBIcon fab icon="twitter" className="dark-grey-text" />
              </MDBBtn>
              <MDBBtn floating tag="a" color="">
                <MDBIcon fab icon="linkedin" className="dark-grey-text" />
              </MDBBtn>
              <p className="card-text mt-3">{auth.specialties}</p>
            </MDBCardBody>
          </MDBCard>
          {/* <MDBCard className="mb-4">
            <MDBCardBody className="text-center">
              <h5>
                <strong>John's Achievements</strong>
              </h5>

              <hr className="my-3" />

              <MDBBtn
                color="light-blue"
                size="sm"
                rounded
                className="px-3"
                onClick={() => this.toggle("bootstrap")}
              >
                Bootstrap Master
              </MDBBtn>
              <MDBBtn
                color="blue-grey"
                size="sm"
                rounded
                className="px-3"
                onClick={() => this.toggle("wordpress")}
              >
                WordPress Master
              </MDBBtn>
              <MDBBtn
                size="sm"
                rounded
                className="px-3"
                onClick={() => this.toggle("angular")}
              >
                Angular Master
              </MDBBtn>
              <MDBBtn
                color="secondary"
                size="sm"
                rounded
                className="px-3"
                onClick={() => this.toggle("mdb")}
              >
                MDB Master
              </MDBBtn>
              <MDBBtn
                color="deep-purple"
                size="sm"
                rounded
                className="px-3"
                onClick={() => this.toggle("community")}
              >
                Community contributor
              </MDBBtn>
              <MDBBtn
                color="indigo"
                size="sm"
                rounded
                className="px-3"
                onClick={() => this.toggle("pro")}
              >
                MDB Pro User
              </MDBBtn>
            </MDBCardBody>
          </MDBCard> */}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
