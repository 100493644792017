import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FIND, RESET } from "../../../../services/redux/slices/businesses";
import { FIND as EMPLOYEES } from "../../../../services/redux/slices/users";

import { fullAddress, globalSearch } from "../../../../services/utilities";
import { useToasts } from "react-toast-notifications";
import Modal from "./modal";
import DataTable from "../../../../components/dataTable";
import moment from "moment";
import { MDBBtn, MDBBtnGroup } from "mdbreact";
import { useHistory } from "react-router";

export default function Businesses() {
  const [businesses, setBusinesses] = useState([]),
    [selected, setSelected] = useState({}),
    [willCreate, setWillCreate] = useState(true),
    [showModal, setShowModal] = useState(false),
    { token, auth } = useSelector(({ auth }) => auth),
    { collections, message, isSuccess, isLoading } = useSelector(
      ({ businesses }) => businesses
    ),
    { addToast } = useToasts(),
    history = useHistory(),
    dispatch = useDispatch();

  useEffect(() => {
    if (token && auth) {
      dispatch(
        FIND({
          token,
          key: {
            owner: `{"$eq": "${auth._id}"}`,
          },
        })
      );
      dispatch(
        EMPLOYEES({
          token,
          key: {
            role: `{"$eq": "647dd9120874515a8fc47afc"}`,
          },
        })
      );
    }

    return () => {
      dispatch(RESET());
    };
  }, [token, auth, dispatch]);

  useEffect(() => {
    setBusinesses(collections.filter(item => item.deleted !== true));
  }, [collections]);

  const toggleModal = () => {
    if (selected._id) {
      setSelected({});
    }
    if (message) {
      dispatch(RESET());
    }
    setShowModal(!showModal);
  };

  const handleUpdate = selected => {
    setSelected(selected);
    if (willCreate) {
      setWillCreate(false);
    }
    toggleModal();
  };

  useEffect(() => {
    if (message) {
      addToast(message, {
        appearance: isSuccess ? "success" : "error",
      });
    }
  }, [isSuccess, message, addToast]);

  const handleSearch = async (willSearch, key) => {
    if (willSearch) {
      setBusinesses(globalSearch(businesses, key));
    } else {
      setBusinesses(collections);
    }
  };

  const handleServices = id => {
    return (
      <MDBBtnGroup className="mx-2">
        <MDBBtn
          className="mx-2"
          color="primary"
          size="sm"
          onClick={() => history.push(`/services/${id}`)}
        >
          Services
        </MDBBtn>
        <MDBBtn
          color="warning"
          size="sm"
          onClick={() => history.push(`/feedbacks/${id}`)}
        >
          Feedbacks
        </MDBBtn>
      </MDBBtnGroup>
    );
  };

  return (
    <>
      <DataTable
        isLoading={isLoading}
        title={"Active Businesses"}
        array={businesses}
        actions={[
          {
            _icon: "pen-alt",
            _function: handleUpdate,
            _haveSelect: true,
            _allowMultiple: false,
            _shouldReset: true,
          },
        ]}
        tableHeads={[
          {
            _text: "Name",
          },
          {
            _text: "Details",
          },
          {
            _text: "Address",
          },
          {
            _text: "Operating Hours",
          },
          {
            _text: "Created At",
          },
          {
            _text: "Action",
          },
        ]}
        tableBodies={[
          {
            _key: "name",
          },
          {
            _key: "details",
          },
          {
            _key: "address",
            _format: data => fullAddress(data),
          },
          {
            _key: "operatingHours",
            _format: data =>
              `${moment(data.start, "HH:mm").format("h:mm A")}-${moment(
                data.end,
                "HH:mm"
              ).format("h:mm A")}`,
          },
          {
            _key: "createdAt",
            _format: data => new Date(data).toLocaleString(),
          },
          {
            _key: "_id",
            _format: data => handleServices(data),
          },
        ]}
        handleSearch={handleSearch}
      />
      <Modal
        key={selected._id || "business-initial-create"}
        selected={selected}
        willCreate={willCreate}
        show={showModal}
        toggle={toggleModal}
      />
    </>
  );
}
