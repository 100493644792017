import React, { useEffect } from "react";
import { MDBBadge, MDBContainer } from "mdbreact";
import ReactCalendar from "./calendar";
import { useDispatch, useSelector } from "react-redux";
import { EMPLOYEE } from "../../../../services/redux/slices/appointments";
export default function Appointments() {
  const { auth, token } = useSelector(({ auth }) => auth),
    dispatch = useDispatch();
  useEffect(() => {
    if (token && auth) {
      dispatch(
        EMPLOYEE({
          token,
          key: {
            client: `{"$eq": "${auth._id}"}`,
          },
        })
      );
    }
  }, [auth, token, dispatch]);

  return (
    <MDBContainer fluid id="v6" className="mb-5">
      <div className="d-flex ">
        <span
          style={{ backgroundColor: "#ffb703" }}
          pill
          className="badge badge-pill text-white "
        >
          Pending
        </span>
        <span
          style={{ backgroundColor: "#e63946" }}
          pill
          className="badge badge-pill text-white "
        >
          Rejected
        </span>
        <span
          style={{ backgroundColor: "#ccd5ae" }}
          pill
          className="badge badge-pill text-white "
        >
          Cancelled
        </span>
        <span
          style={{ backgroundColor: "#a8dadc" }}
          pill
          className="badge badge-pill text-white "
        >
          Rescheduled
        </span>
        <span
          style={{ backgroundColor: "#06d6a0" }}
          pill
          className="badge badge-pill text-white "
        >
          Confirmed
        </span>
        <span
          style={{ backgroundColor: "#80ed99" }}
          pill
          className="badge badge-pill text-white "
        >
          Completed
        </span>
        <span
          style={{ backgroundColor: "#g001524" }}
          pill
          className="badge badge-pill text-info"
        >
          Undefined
        </span>
      </div>
      <ReactCalendar />
    </MDBContainer>
  );
}
