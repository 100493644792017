import React, { useEffect, useState } from "react";
import Calendar from "react-big-calendar";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { EMPLOYEE } from "../../../../services/redux/slices/appointments";
import { fullName } from "../../../../services/utilities";

export default function ReactCalendar() {
  const { collections } = useSelector(({ schedules }) => schedules),
    APPOINTMENTS = useSelector(({ appointments }) => appointments.collections),
    { token } = useSelector(({ auth }) => auth),
    [schedules, setSchedules] = useState([]),
    dispatch = useDispatch();

  useEffect(() => {
    var sheds = [];
    collections.forEach(item => {
      sheds.push(`"${item._id}"`);
    });
    if (sheds.length > 0) {
      dispatch(
        EMPLOYEE({
          token,
          key: {
            schedule: `{"$in": [${sheds}]}`,
          },
        })
      );
    }
  }, [collections, token, dispatch]);

  const handleStatus = status => {
    switch (status) {
      case "Pending":
        return "#ffb703";
      case "Rejected":
        return "#e63946";
      case "Cancelled":
        return "#ccd5ae";
      case "Rescheduled":
        return "#a8dadc";
      case "Confirmed":
        return "#06d6a0";
      case "Completed":
        return "#80ed99";

      default:
        return "#G001524";
    }
  };
  useEffect(() => {
    let appointments = [];
    APPOINTMENTS.filter(e => e.deleted === false).forEach(item => {
      appointments.push({
        title: `${fullName(item?.schedule?.employee?.fullName)} notes: ${
          item.notes
        } status: ${item.status}, Service: ${item?.service?.name}`,

        start: new Date(
          item.date +
            "T" +
            moment(item?.schedule?.time, "HH:mm").format("hh:mm")
        ),
        end: new Date(
          item.date +
            "T" +
            moment(item?.schedule?.time, "HH:mm").format("hh:mm")
        ),
        bgColor: handleStatus(item.status),
      });
    });
    setSchedules(appointments);
  }, [APPOINTMENTS, collections]);
  return (
    <Calendar
      eventPropGetter={(event, start, end, isSelected) => {
        return {
          style: { backgroundColor: event.bgColor },
        };
      }}
      localizer={Calendar.momentLocalizer(moment)}
      defaultDate={new Date()}
      defaultView="agenda"
      events={schedules}
      step={60}
      popup={true}
      style={{ height: "70vh", marginBottom: "1.5rem" }}
    />
  );
}
