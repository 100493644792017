import React, { useEffect, useState } from "react";
import Calendar from "react-big-calendar";
import moment from "moment";
import { useSelector } from "react-redux";

export default function ReactCalendar() {
  const { collections } = useSelector(({ schedules }) => schedules),
    [schedules, setSchedules] = useState([]);

  useEffect(() => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var now = new Date().setMonth(new Date().getMonth() + 12);
    var sheds = [];
    for (
      let index = new Date();
      index <= now;
      index.setDate(index.getDate() + 1)
    ) {
      collections.forEach(item => {
        item.day === days[index.getDay() - 1] &&
          sheds.push({
            title: moment(item.time, "HH:mm:ss").format("hh:mm A"),

            start: new Date(
              index.toISOString().split("T")[0] +
                "T" +
                moment(item.time, "HH:mm").format("hh:mm")
            ),
            end: new Date(
              index.toISOString().split("T")[0] +
                "T" +
                moment(item.time, "HH:mm").format("hh:mm")
            ),
          });
      });
    }
    setSchedules(sheds);
  }, [collections]);
  return (
    <Calendar
      localizer={Calendar.momentLocalizer(moment)}
      defaultDate={new Date()}
      defaultView="month"
      events={schedules}
      style={{ height: "70vh", marginBottom: "1.5rem" }}
    />
  );
}
