import Appointments from "./appointments";
import Bots from "./bots";
import Dashboard from "./dashboard";
import Feedbacks from "./feedbacks";
import Services from "./services";

const routes = [
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/services/:id",
    component: Services,
  },
  {
    path: "/feedbacks/:id",
    component: Feedbacks,
  },
  {
    path: "/chat/:company",
    component: Bots,
  },
  {
    path: "/bookings",
    component: Appointments,
  },
  {
    path: "/bots",
    component: Bots,
  },
];

export default routes;
