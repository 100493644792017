const sidebar = [
  {
    name: "Dashboard",
    icon: "tachometer-alt",
    path: "/dashboard",
  },
  {
    name: "User Settings",
    icon: "user-cog",
    children: [
      {
        name: "Roles",
        path: "/roles",
      },
      {
        name: "Users",
        path: "/users",
      },
    ],
  },
  {
    name: "Businesses",
    icon: "building",
    path: "/businesses",
  },
  {
    name: "Services",
    icon: "tools",
    path: "/services",
  },
  {
    name: "Schedules",
    icon: "calendar-alt",
    path: "/schedules",
  },
  {
    name: "Appointments",
    icon: "clock",
    path: "/bookings",
  },
];

export default sidebar;
