import Appointments from "./appointments";
import Businesses from "./businesses";
import Dashboard from "./dashboard";
import Schedules from "./schedules";
import Services from "./services";
import Roles from "./user settings/roles";
import Users from "./user settings/users";

const routes = [
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/roles",
    component: Roles,
  },
  {
    path: "/users",
    component: Users,
  },
  {
    path: "/businesses",
    component: Businesses,
  },
  {
    path: "/services",
    component: Services,
  },
  {
    path: "/schedules",
    component: Schedules,
  },
  {
    path: "/bookings",
    component: Appointments,
  },
];

export default routes;
