import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBIcon,
  MDBModalHeader,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
} from "mdbreact";
import { SAVE, UPDATE } from "../../../../services/redux/slices/schedules";
import { fullName } from "../../../../services/utilities";

export default function Modal({ show, toggle, selected, willCreate }) {
  const { isLoading } = useSelector(({ schedules }) => schedules),
    BUSINESSES = useSelector(({ businesses }) => businesses.collections),
    { token } = useSelector(({ auth }) => auth),
    [form, setForm] = useState({
      business: "",
      name: "",
      details: "",
      price: 0,
    }),
    [employees, setEmployees] = useState([]),
    days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    dispatch = useDispatch();

  const handleUpdate = () => {
    dispatch(
      UPDATE({
        data: { ...form, _id: selected._id },
        token,
      })
    );

    setForm({
      business: "",
      name: "",
      details: "",
      price: 0,
    });
    toggle();
  };

  const handleCreate = () => {
    dispatch(
      SAVE({
        data: { ...form, _id: selected._id },
        token,
      })
    );

    setForm({
      business: "",
      name: "",
      details: "",
      price: 0,
    });
    toggle();
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (willCreate) {
      handleCreate();
    } else {
      handleUpdate();
    }
  };

  useEffect(() => {
    let emps = [];
    BUSINESSES.forEach(b => {
      emps = [...emps, ...b.employees];
    });
    console.log(emps);
    setEmployees(emps);
  }, [BUSINESSES]);

  useEffect(() => {
    const handleInject = async () => {
      if (selected._id) {
        let updates = {
          _id: selected._id,
          business: selected?.business?._id,
          name: selected.name,
          details: selected.details,
          price: selected.price,
          employees: [],
        };

        if (selected.employee && selected.employee._id) {
          updates.employee = selected.employee._id;
        }

        setForm(updates);
      }
    };
    handleInject();
  }, [selected]);

  const handleChange = (key, value) => setForm({ ...form, [key]: value });

  return (
    <MDBModal
      isOpen={show}
      toggle={toggle}
      backdrop={true}
      disableFocusTrap={false}
    >
      <MDBModalHeader
        toggle={toggle}
        className="light-blue darken-3 white-text"
      >
        <MDBIcon icon="user" className="mr-2" />
        {willCreate ? "Create" : "Update"} {selected.name || "a Schedule"}
      </MDBModalHeader>
      <MDBModalBody className="mb-0">
        <form onSubmit={handleSubmit}>
          <MDBRow>
            <MDBCol>
              <MDBSelect
                getValue={e => handleChange("employee", e[0])}
                className="colorful-select dropdown-primary hidden-md-down"
                label="Employee"
              >
                <MDBSelectInput />
                <MDBSelectOptions>
                  {employees.map((emps, index) => {
                    let isSelected = false;

                    if (selected._id) {
                      const _emps = selected.employee;
                      if (_emps === emps._id) {
                        isSelected = true;
                      }
                    }

                    return (
                      <MDBSelectOption
                        key={`emps-list-${index}`}
                        value={emps._id}
                        selected={isSelected}
                      >
                        {fullName(emps.fullName)}
                      </MDBSelectOption>
                    );
                  })}
                </MDBSelectOptions>
              </MDBSelect>
              <MDBInput
                type="time"
                label="Time"
                value={willCreate ? form.time : form.time || selected.time}
                onChange={e => setForm({ ...form, time: e.target.value })}
                required
                icon="clock"
              />

              <MDBSelect
                getValue={e => handleChange("day", e[0])}
                className="colorful-select dropdown-primary hidden-md-down"
                label="Day"
              >
                <MDBSelectInput />
                <MDBSelectOptions>
                  {days.map((day, index) => {
                    let isSelected = false;

                    if (selected._id) {
                      const _day = selected.day;
                      if (_day === day) {
                        isSelected = true;
                      }
                    }

                    return (
                      <MDBSelectOption
                        key={`day-list-${index}`}
                        value={day}
                        selected={isSelected}
                      >
                        {day}
                      </MDBSelectOption>
                    );
                  })}
                </MDBSelectOptions>
              </MDBSelect>
            </MDBCol>
          </MDBRow>
          <div className="text-center mb-1-half">
            <MDBBtn
              type="submit"
              disabled={isLoading}
              color="info"
              className="mb-2"
              rounded
            >
              {willCreate ? "submit" : "update"}
            </MDBBtn>
          </div>
        </form>
      </MDBModalBody>
    </MDBModal>
  );
}
