import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BROWSE,
  DESTROY,
  RESET,
  UPDATE,
} from "../../../../services/redux/slices/appointments";
import {
  FIND as ADMIN,
  RESET as USERRESET,
} from "../../../../services/redux/slices/users";
import {
  BROWSE as SCHEDULES,
  RESET as SCHEDRESET,
} from "../../../../services/redux/slices/schedules";
import { fullName, globalSearch } from "../../../../services/utilities";
import { useToasts } from "react-toast-notifications";
import Swal from "sweetalert2";
import Modal from "./modal";
import DataTable from "../../../../components/dataTable";
import { MDBBadge } from "mdbreact";

export default function Appointments() {
  const [isActive, setIsActive] = useState(true),
    { collections, message, isSuccess, isLoading } = useSelector(
      ({ appointments }) => appointments
    ),
    [appointments, setAppointments] = useState([]),
    [selected, setSelected] = useState({}),
    [willCreate, setWillCreate] = useState(true),
    [showModal, setShowModal] = useState(false),
    { token } = useSelector(({ auth }) => auth),
    { addToast } = useToasts(),
    dispatch = useDispatch();

  const handleSwitch = () => setIsActive(!isActive);

  useEffect(() => {
    if (token) {
      dispatch(BROWSE(token));
      dispatch(SCHEDULES(token));
      dispatch(
        ADMIN({
          token,
          key: {
            role: `{"$eq": "647dd9120874515a8fc47afb"}`,
          },
        })
      );
    }

    return () => {
      dispatch(RESET());
      dispatch(USERRESET());
      dispatch(SCHEDRESET());
    };
  }, [token, dispatch]);

  useEffect(() => {
    setAppointments(collections.filter(item => item.deleted !== isActive));
  }, [isActive, collections]);

  const toggleModal = () => {
    if (selected._id) {
      setSelected({});
    }
    if (message) {
      dispatch(RESET());
    }
    setShowModal(!showModal);
  };

  const handleUpdate = selected => {
    setSelected(selected);
    if (willCreate) {
      setWillCreate(false);
    }
    toggleModal();
  };

  const handleCreate = () => {
    if (!willCreate) {
      setWillCreate(true);
    }
    toggleModal();
  };

  const handleDestroy = selected =>
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to delete ${selected.length} Appointment(s).`,
      icon: "question",
      confirmButtonText: "Proceed",
    }).then(res => {
      if (res.isConfirmed) {
        const toDestroy = selected.map(item => ({
          _id: item._id,
          deleted: true,
        }));

        dispatch(
          DESTROY({
            data: toDestroy.length > 1 ? toDestroy : toDestroy[0],
            token,
          })
        );
      }
    });

  const handleRestore = selected =>
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to restore ${selected.length} Appointment(s).`,
      icon: "question",
      confirmButtonText: "Proceed",
    }).then(res => {
      if (res.isConfirmed) {
        const toRestore = selected.map(item => ({
          _id: item._id,
          deleted: false,
        }));

        dispatch(
          UPDATE({
            data: toRestore.length > 1 ? toRestore : toRestore[0],
            token,
          })
        );
      }
    });

  useEffect(() => {
    if (message) {
      addToast(message, {
        appearance: isSuccess ? "success" : "error",
      });
    }
  }, [isSuccess, message, addToast]);

  const handleSearch = async (willSearch, key) => {
    if (willSearch) {
      setAppointments(globalSearch(appointments, key));
    } else {
      setAppointments(collections);
    }
  };

  return (
    <>
      <DataTable
        isLoading={isLoading}
        title={isActive ? "Active Appointments" : "Archive Appointments"}
        array={appointments}
        actions={[
          {
            _icon: "plus",
            _function: handleCreate,
            _condition: () => isActive,
            _shouldReset: true,
            _disabledOnSearch: true,
          },

          {
            _icon: isActive ? "pen-alt" : "undo-alt",
            _function: isActive ? handleUpdate : handleRestore,
            _haveSelect: true,
            _allowMultiple: !isActive,
            _shouldReset: true,
          },
          {
            _icon: "trash",
            _function: handleDestroy,
            _condition: () => isActive,
            _haveSelect: true,
            _shouldReset: true,
          },
          {
            _icon: "sync",
            _function: handleSwitch,
            _shouldReset: true,
            _disabledOnSearch: true,
          },
        ]}
        tableHeads={[
          {
            _text: "Schedule",
          },
          {
            _text: "Client",
          },
          {
            _text: "Date",
          },
          {
            _text: "status",
          },
          {
            _text: "notes",
          },
          {
            _text: "Updated At",
          },
          {
            _text: "Created At",
          },
        ]}
        tableBodies={[
          {
            _key: "schedule",
            _format: data => {
              return `${fullName(data?.employee?.fullName)}- ${data.day}: ${
                data.time
              }`;
            },
          },
          {
            _key: "client",
            _format: data => fullName(data?.fullName),
          },
          {
            _key: "date",
            _format: data => new Date(data).toISOString().split("T")[0],
          },
          {
            _key: "status",
            _format: data => {
              let color = {
                pending: "warning",
                confirm: "success",
                reschedule: "info",
                cancelled: "danger",
              };
              return (
                <MDBBadge color={color[data.toLowerCase()]} className="m-2 p-2">
                  {data}
                </MDBBadge>
              );
            },
          },
          {
            _key: "notes",
          },
          {
            _key: "updatedAt",
            _format: data => new Date(data).toLocaleString(),
          },
          {
            _key: "createdAt",
            _format: data => new Date(data).toLocaleString(),
          },
        ]}
        disableSearch={!isActive}
        handleSearch={handleSearch}
        toggleComponent={isActive}
      />
      <Modal
        key={selected._id || "service-initial-create"}
        selected={selected}
        willCreate={willCreate}
        show={showModal}
        toggle={toggleModal}
      />
    </>
  );
}
