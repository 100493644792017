import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FIND, RESET, SAVE } from "../../../../services/redux/slices/feedbacks";
import {
  BROWSE as APPOINTMENT,
  RESET as APPOINTMENT_RESET,
} from "../../../../services/redux/slices/appointments";
import { useToasts } from "react-toast-notifications";
import {
  MDBBadge,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBInput,
  MDBTypography,
} from "mdbreact";
import { useParams } from "react-router";
import ReactStars from "react-stars";

export default function Feedbacks() {
  const [isActive, setIsActive] = useState(true),
    { collections, message, isSuccess, isLoading } = useSelector(
      ({ feedbacks }) => feedbacks
    ),
    appointments = useSelector(({ appointments }) => appointments.collections),
    { auth } = useSelector(({ auth }) => auth),
    [appointment, setAppointment] = useState([]),
    { token } = useSelector(({ auth }) => auth),
    { id } = useParams(),
    [form, setForm] = useState({
      appointment: id,
      rate: 0,
      business: "",
      client: auth._id,
      message: "",
    }),
    { addToast } = useToasts(),
    [rated, setRated] = useState(false),
    dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(
        FIND({
          token,
          key: {
            appointment: `{"$eq": "${id}"}`,
          },
        })
      );
      dispatch(APPOINTMENT(token));
    }

    return () => {
      dispatch(RESET());
      dispatch(APPOINTMENT_RESET());
    };
  }, [token, auth, id, dispatch]);

  useEffect(() => {
    setRated(collections.length > 0);
    let i = appointments.findIndex(item => item._id === id);
    setForm({ ...form, business: appointments[i]?.service?.business?._id });
    setAppointment(appointments[i]);
  }, [collections, appointments]);

  useEffect(() => {
    if (message) {
      addToast(message, {
        appearance: isSuccess ? "success" : "error",
      });
    }
  }, [isSuccess, message, addToast]);

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(
      SAVE({
        data: { ...form },
        token,
      })
    );
  };

  return (
    <MDBContainer>
      <MDBCard alignment="center">
        <MDBCardHeader>
          Feedback to {appointment?.service?.business?.name}
        </MDBCardHeader>
        {rated ? (
          <MDBBadge>
            <MDBTypography variant="h1-responsive p-5">
              You already rated this appointment
            </MDBTypography>
          </MDBBadge>
        ) : (
          <form onSubmit={handleSubmit}>
            <MDBCardBody>
              <MDBInput
                onChange={e => setForm({ ...form, message: e.target.value })}
                type="textarea"
                placeHolder={"Write Your Feedback here..."}
              />
              <div className="d-flex align-items-center justify-content-center">
                <ReactStars
                  count={5}
                  value={form.rate}
                  onChange={e => setForm({ ...form, rate: e })}
                  size={80}
                  color2={"#ffd700"}
                />
              </div>
            </MDBCardBody>
            <MDBBtn
              type="submit"
              disabled={isLoading}
              className="mx-auto w-100"
              size="xxl"
              color="success"
            >
              Submit
            </MDBBtn>
          </form>
        )}
      </MDBCard>
    </MDBContainer>
  );
}
