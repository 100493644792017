import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBIcon,
  MDBModalHeader,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
} from "mdbreact";
import { SAVE, UPDATE } from "../../../../services/redux/slices/businesses";
import {
  ENDPOINT,
  PresetImage,
  fullName,
} from "../../../../services/utilities";
import moment from "moment";

export default function Modal({ show, toggle, selected, willCreate }) {
  const { isLoading } = useSelector(({ businesses }) => businesses),
    { collections } = useSelector(({ users }) => users),
    { token } = useSelector(({ auth }) => auth),
    [form, setForm] = useState({
      operatingHours: { start: "00:00", end: "00:00" },
      owner: "",
      name: "",
      details: "",
      address: {
        street: "",
        barangay: "",
        city: "",
        region: "",
        province: "",
      },
    }),
    [employees, setEmployees] = useState([]),
    dispatch = useDispatch();

  const handleUpdate = () => {
    dispatch(
      UPDATE({
        data: { ...form, _id: selected._id },
        token,
      })
    );

    setForm({
      operatingHours: { start: "00:00", end: "00:00" },
      owner: "",
      name: "",
      details: "",
      address: {
        street: "",
        barangay: "",
        city: "",
        region: "",
        province: "",
      },
    });
    toggle();
  };

  const handleCreate = () => {
    dispatch(
      SAVE({
        data: { ...form, _id: selected._id },
        token,
      })
    );

    setForm({
      owner: "",
      operatingHours: { start: "00:00", end: "00:00" },
      name: "",
      details: "",
      address: {
        street: "",
        barangay: "",
        city: "",
        region: "",
        province: "",
      },
    });
    toggle();
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (willCreate) {
      handleCreate();
    } else {
      handleUpdate();
    }
  };

  useEffect(() => {
    setEmployees(collections.filter(e => e.role?.name === "EMPLOYEE"));
  }, [collections]);

  useEffect(() => {
    const handleInject = async () => {
      if (selected._id) {
        let updates = {
          _id: selected._id,
          owner: selected?.owner?._id,
          name: selected.name,
          details: selected.details,
          employees: [],
          operatingHours: {
            start: selected?.operatingHours?.start,
            end: selected?.operatingHours?.end,
          },
          address: {
            street: selected?.address.street,
            barangay: selected?.address.barangay,
            city: selected?.address.city,
            region: selected?.address.region,
            province: selected?.address.province,
          },
        };

        if (selected.owner && selected.owner._id) {
          updates.owner = selected.owner._id;
        }

        if (selected.employees && selected.employees.length > 0) {
          await selected.employees.forEach(dev => {
            updates.employees.push(dev._id);
          });
        }
        setForm(updates);
      }
    };
    handleInject();
  }, [selected]);

  const handleObjChange = (obj, key, value) => {
    const _obj = { ...form[obj] };
    _obj[key] = value.toUpperCase();
    setForm({ ...form, [obj]: _obj });
  };

  const handleChange = (key, value) => setForm({ ...form, [key]: value });

  const handleImage = () => document.getElementById("uploadImage").click();

  const handleImageChange = e => {
    document.getElementById("image").src = URL.createObjectURL(
      e.target.files[0]
    );
    const reader = new FileReader();
    reader.onload = e => {
      let image = new Image();
      image.src = e.target.result;

      image.onload = function () {
        handleChange("img", reader.result.split(",")[1]);
      };
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  return (
    <MDBModal
      size="lg"
      isOpen={show}
      toggle={toggle}
      backdrop={true}
      disableFocusTrap={false}
    >
      <MDBModalHeader
        toggle={toggle}
        className="light-blue darken-3 white-text"
      >
        <MDBIcon icon="user" className="mr-2" />
        {willCreate ? "Create" : "Update"} {selected.name || "a Business"}
      </MDBModalHeader>
      <MDBModalBody className="mb-0">
        <form onSubmit={handleSubmit}>
          <MDBRow>
            <MDBCol md="6">
              <MDBInput
                type="text"
                label="Name"
                value={willCreate ? form.name : form.name || selected.name}
                onChange={e =>
                  setForm({ ...form, name: e.target.value.toUpperCase() })
                }
                required
                icon="building"
              />
              <MDBInput
                type="textarea"
                label="Details"
                value={
                  willCreate ? form.details : form.details || selected.details
                }
                onChange={e => setForm({ ...form, details: e.target.value })}
                required
                icon="heading"
              />
              <label htmlFor="">Operating Hours</label>

              <MDBInput
                type="time"
                label="Start"
                value={
                  willCreate
                    ? form?.operatingHours?.start
                    : form?.operatingHours?.start ||
                      selected?.operatingHours?.start
                }
                onChange={e =>
                  handleObjChange("operatingHours", "start", e.target.value)
                }
                required
                icon="clock"
              />
              <MDBInput
                type="time"
                label="End"
                min={
                  willCreate
                    ? form?.operatingHours?.start
                    : moment(form?.operatingHours?.start, "HH:mm")
                        .add(1, "hour")
                        .format("HH:mm") ||
                      moment(selected?.operatingHours?.start, "HH:mm")
                        .add(1, "hour")
                        .format("HH:mm")
                }
                value={
                  willCreate
                    ? form?.operatingHours?.end
                    : form?.operatingHours?.end || selected?.operatingHours?.end
                }
                onChange={e =>
                  handleObjChange("operatingHours", "end", e.target.value)
                }
                required
                icon="clock"
              />
              <MDBSelect
                multiple
                getValue={e => handleChange("employees", e)}
                className="colorful-select dropdown-primary hidden-md-down"
                label="Employees"
              >
                <MDBSelectInput />
                <MDBSelectOptions>
                  {employees.map((emps, index) => {
                    let isSelected = false;

                    if (selected._id) {
                      const _emps = selected.employees;
                      if (
                        _emps.length > 0 &&
                        _emps.some(e => e._id === emps._id)
                      ) {
                        isSelected = true;
                      }
                    }

                    return (
                      <MDBSelectOption
                        key={`emps-list-${index}`}
                        value={emps._id}
                        selected={isSelected}
                      >
                        {fullName(emps.fullName)}
                      </MDBSelectOption>
                    );
                  })}
                </MDBSelectOptions>
              </MDBSelect>
            </MDBCol>
            <MDBCol md="6">
              <MDBInput
                type="text"
                label="Street"
                value={
                  willCreate
                    ? form?.address?.street
                    : form?.address?.street || selected?.address?.street
                }
                onChange={e =>
                  handleObjChange("address", "street", e.target.value)
                }
                required
                icon="map-marker-alt"
              />

              <MDBInput
                type="text"
                label="Barangay"
                value={
                  willCreate
                    ? form?.address?.barangay
                    : form?.address?.barangay || selected?.address?.barangay
                }
                onChange={e =>
                  handleObjChange("address", "barangay", e.target.value)
                }
                required
                icon="map-marker-alt"
              />

              <MDBInput
                type="text"
                label="City"
                value={
                  willCreate
                    ? form?.address?.city
                    : form?.address?.city || selected?.address?.city
                }
                onChange={e =>
                  handleObjChange("address", "city", e.target.value)
                }
                required
                icon="map-marker-alt"
              />

              <MDBInput
                type="text"
                label="Region"
                value={
                  willCreate
                    ? form?.address?.region
                    : form?.address?.region || selected?.address?.region
                }
                onChange={e =>
                  handleObjChange("address", "region", e.target.value)
                }
                required
                icon="map-marker-alt"
              />
              <MDBInput
                type="text"
                label="Provice"
                value={
                  willCreate
                    ? form?.address?.province
                    : form?.address?.province || selected?.address?.province
                }
                onChange={e =>
                  handleObjChange("address", "province", e.target.value)
                }
                required
                icon="map-marker-alt"
              />
            </MDBCol>

            <MDBCol md={"12"} className="mt-2">
              <div
                className="bg-image mx-auto hover-overlay"
                style={{ maxWidth: 400 }}
              >
                <img
                  id="image"
                  src={`${ENDPOINT}/assets/businesses/${form._id}.png`}
                  style={{ width: 400, height: 200 }}
                  className="img-fluid"
                  alt={"project"}
                  onError={e => (e.target.src = PresetImage(false))}
                />
                <a href="#!">
                  <div
                    className="mask overlay d-flex align-items-center"
                    // style={{ backgroundColor: "rgba(57, 192, 237, 0.2)" }}
                  >
                    <input
                      type="file"
                      id="uploadImage"
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                      accept="image/png"
                    />
                    <MDBBtn
                      onClick={handleImage}
                      color="success"
                      className="mx-auto"
                    >
                      <MDBIcon icon="upload" />
                    </MDBBtn>
                  </div>
                </a>
              </div>
            </MDBCol>
          </MDBRow>
          <div className="text-center mb-1-half">
            <MDBBtn
              type="submit"
              disabled={isLoading}
              color="info"
              className="mb-2"
              rounded
            >
              {willCreate ? "submit" : "update"}
            </MDBBtn>
          </div>
        </form>
      </MDBModalBody>
    </MDBModal>
  );
}
