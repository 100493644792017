import React, { useState, useEffect } from "react";
import {
  MDBSideNavLink,
  MDBSideNavCat,
  MDBSideNavNav,
  MDBSideNav,
  MDBIcon,
} from "mdbreact";
import ADMINISTRATOR from "../../pages/platforms/administrator/sidebar";
import SUPERADMIN from "../../pages/platforms/superadmin/sidebar";
import EMPLOYEE from "../../pages/platforms/employee/sidebar";
import CLIENT from "../../pages/platforms/client/sidebar";
import { useSelector } from "react-redux";
import Logo from "../../assets/logo.png";

export default function SideNavigation({
  triggerOpening,
  breakWidth,
  onLinkClick,
}) {
  const [links, setLinks] = useState([]),
    { role } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (role.name) {
      const collection = {
        SUPERADMIN,
        ADMINISTRATOR,
        EMPLOYEE,
        CLIENT,
      };
      setLinks(collection[role.name] || []);
    }
  }, [role]);

  const handleLinks = () => {
    if (links.length > 0) {
      return links?.map(({ path, name, icon, children }, index) =>
        path ? (
          <MDBSideNavLink
            key={`sidebar-${index}`}
            to={path}
            topLevel
            onClick={onLinkClick}
          >
            <MDBIcon icon={`${icon} mr-2`} />
            {name}
          </MDBSideNavLink>
        ) : (
          <MDBSideNavCat
            id={`${name}-cat`}
            name={name}
            key={`sidebar-${index}`}
            icon={icon}
          >
            {children.map((child, cIndex) => (
              <MDBSideNavLink
                key={`sidebar-${index}-${cIndex}`}
                to={child.path}
                onClick={onLinkClick}
              >
                {child.name}
              </MDBSideNavLink>
            ))}
          </MDBSideNavCat>
        )
      );
    } else {
      return (
        <MDBSideNavLink to="/" topLevel onClick={onLinkClick}>
          <MDBIcon icon="home mr-2" />
          Home
        </MDBSideNavLink>
      );
    }
  };

  return (
    <div className="white-skin">
      <MDBSideNav
        bg="https://mdbootstrap.com/img/Photos/Others/sidenav2.jpg"
        mask="strong"
        href="/dashboard"
        fixed
        breakWidth={breakWidth}
        triggerOpening={triggerOpening}
        style={{ transition: "padding-left .3s" }}
      >
        <div className="text-center text-info font-bold h4 m-2 header">
          <img src={Logo} alt="logo" width={"100px"} />
        </div>
        <MDBSideNavNav>{handleLinks()}</MDBSideNavNav>
      </MDBSideNav>
    </div>
  );
}
