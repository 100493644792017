import React, { useEffect } from "react";
import { MDBContainer } from "mdbreact";
import ReactCalendar from "./calendar";
import { useDispatch, useSelector } from "react-redux";
import { FIND } from "../../../../services/redux/slices/schedules";
export default function Dashboard() {
  const { auth, token } = useSelector(({ auth }) => auth),
    dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      FIND({
        token,
        key: {
          employee: `{"$eq": "${auth._id}"}`,
        },
      })
    );
  }, [auth, token, dispatch]);
  return (
    <MDBContainer fluid id="v6" className="mb-5">
      <ReactCalendar />
    </MDBContainer>
  );
}
