const sidebar = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: "tachometer-alt",
  },
  {
    name: "Appointments",
    path: "/bookings",
    icon: "calendar-alt",
  },
];

export default sidebar;
